








import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RunTask } from '@/store/interface/Task';
import { Submission } from '@/store/interface/Grade';
import QuizFacilitatorSummary from '@/components/quiz/QuizFacilitatorSummary.vue';

@Component({
  components: { QuizFacilitatorSummary },
})
export default class QuizStudentReview extends Vue {
  @Prop(Object)
  runTask!: RunTask;

  @Prop(Object)
  studentSubmission!: Submission;

  get correctPercentage (): number {
    const { correct, partlyCorrect, total } = this.taskCountStates;
    if (total > 0) {
      const correctPercentage = ((correct + (partlyCorrect * 0.5)) / total) * 100;
      return Math.round(correctPercentage) / 100;
    }

    return -1;
  }

  get correctPercent (): string {
    const percent = this.correctPercentage;
    if (percent >= 0) {
      return percent.toLocaleString('en', { style: 'percent' });
    }

    return 'Err';
  }

  get taskCountStates (): Record<string, number> {
    let correct = 0;
    let partlyCorrect = 0;
    let incorrect = 0;

    if ('quizResult' in this.studentSubmission && this.studentSubmission.quizResult) {
      correct = this.studentSubmission.quizResult.correct;
      partlyCorrect = this.studentSubmission.quizResult.partlyCorrect;
      incorrect = this.studentSubmission.quizResult.incorrect;
    }

    return {
      correct,
      incorrect,
      partlyCorrect,
      undecided: 0,
      total: correct + partlyCorrect + incorrect,
    };
  }
}
